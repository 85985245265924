@import "textarea";
@import "media";
@import "editor";
@import "quests";


.crosspub {
    display: flex;
    flex-direction: column;
    width: 100%;
    > button {
        max-width: 120px;
    }
    .crosspub__row {
        display: flex;
        flex-direction: row;
        align-self: center;
        width: 100%;
        > .ant-row {
            flex: 1;
            margin-right: 10px;;
        }
        button {
            position: relative;
            top: 42px;
        }
    }
}